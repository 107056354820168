import React from "react";
import { onlyDate, onlyTime } from "../util/functions";
import { navigate } from "gatsby";

function ConsultHistoryCard({ data }) {
  return (
    <div className="shadow rounded-xl m-4 ">
      <div className="bg-gray-100 flex justify-between p-2 rounded-t-xl">
        <h1>{data?.astrologer?.name}</h1>
        <h2 className="text-yellow-500">completed</h2>
      </div>
      <h2 className="text-gray-500 ml-2 mt-1">Mode of Communication</h2>
      <div className="flex p-2">
        {data?.mode == "chat" ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fc5203"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fc5203"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
            />
          </svg>
        )}
        <h2 className="ml-2">{data?.mode ?? "Call"}</h2>
      </div>
      <div className="flex ml-2 mt-2">
        <div className="w-1/2 flex flex-col">
          <h1 className="text-gray-500">Date</h1>
          <div className="flex mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fc5203"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <h1 className="ml-2">{onlyDate(data?.startTime)}</h1>
          </div>
        </div>
        <div className="w-1/2 flex flex-col">
          <h1 className="text-gray-500">Time</h1>
          <div className="flex mt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fc5203"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <h1 className="ml-2">{onlyTime(data?.startTime)}</h1>
          </div>
        </div>
      </div>
      <h1 className="ml-2 mt-2 text-gray-500">Total Charges</h1>
      <h2 className="ml-2 mt-1">
        {(data?.currencyType === "inr" ? "₹" : "$") +
          " " +
          data?.totalAmountPaidWithTax}
      </h2>
      <div className="flex mb-4">
        <button
          className="m-2 bg-white border-2 border-yellow-500 rounded-full py-2 px-8 text-yellow-500"
          onClick={() => {
            navigate(`/astrologers/${data?.astrologer?._id}`, {
                state: data?.astrologer?._id,
              });
          }}
        >
          Consult Again
        </button>
        {/* <button className="m-1 bg-white border-2 border-yellow-500 rounded-full py-2 px-8 text-yellow-500" onClick={() => { navigate('/consultationEnd', { state: data?._id }) }}>Consult Detail</button> */}
      </div>
    </div>
  );
}

export default ConsultHistoryCard;
